
import { Component, Mixins } from 'vue-property-decorator';

import { SettingsModule } from '@/store/modules/settings';
import { AppMain, Navbar, Sidebar } from './components';
import ResizeMixin from './mixin/resize';
import { UserModule } from '@/store/modules/user';

@Component({
  name: 'Layout',
  components: {
    AppMain,
    Navbar,
    Sidebar,
  },
})
export default class extends Mixins(ResizeMixin) {
  get classObj() {
    return {
      hideSidebar: !this.sidebar.opened,
      withoutAnimation: this.sidebar.withoutAnimation,
    };
  }

  get showSettings() {
    return SettingsModule.showSettings;
  }

  get fixedHeader() {
    return SettingsModule.fixedHeader;
  }

  get expiresIn() {
    return UserModule.expiresIn;
  }

  get loginTime() {
    return UserModule.loginTime;
  }

  get token() {
    return UserModule.token;
  }

  // 全局定时器
  private timer: any = null;

  public async created() {
    await this.checkExpires();
    this.getUser();
    this.getUserRoot();
  }

  public beforeDestroy() {
    this.clearExpiresInterval();
  }

  // 定时计算超时时间
  private expiresInterval() {
    this.timer = setInterval(this.checkExpires, 1000);
  }

  private clearExpiresInterval() {
    clearInterval(this.timer);
    this.timer = null;
  }
  // 检查是否token超时失效，如失效则刷新token
  private async checkExpires() {
    const nowDate = new Date().getTime();
    const loginDate = new Date(this.loginTime).getTime();
    if (nowDate - loginDate >= +this.expiresIn * 1000) {
      try {
        await UserModule.RefreshToken();
      } catch (err) {
        this.clearExpiresInterval();
      }
    }
  }

  private getUser() {
    if (this.token) {
      UserModule.GetUser();
      this.expiresInterval();
    }
  }

  private getUserRoot() {
    if (this.token) {
      UserModule.GetUserRoot();
    }
  }

}
