/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'search': {
    width: 200,
    height: 200,
    viewBox: '0 0 1025 1024',
    data: '<path pid="0" d="M153.745 473.59a319.952 319.952 0 11639.905-8.472 319.952 319.952 0 01-639.905 8.473zm796.094 411.732L793.8 729.208a410.457 410.457 0 00-64.185-577.144 410.682 410.682 0 00-577.144 64.26 410.532 410.532 0 00577.82 576.62l155.964 155.964a44.99 44.99 0 0065.235-61.936l-1.65-1.65z"/>'
  }
})
