/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'data': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M182.056 870.104a32.337 32.337 0 01-34.088-34.089V174.861A100.433 100.433 0 01250.233 72.596h518.144a100.433 100.433 0 01102.266 102.265V743.99a34.088 34.088 0 01-68.177 0V174.86a32.337 32.337 0 00-34.089-34.088H250.233a32.337 32.337 0 00-34.088 34.088v661.316a34.17 34.17 0 01-34.089 33.927z"/><path pid="1" d="M686.538 348.564h-344.28a34.088 34.088 0 110-68.177h344.28a32.337 32.337 0 0134.089 34.089 34.17 34.17 0 01-34.089 34.088zm0 136.354h-344.28a34.088 34.088 0 110-68.177h344.28a32.337 32.337 0 0134.089 34.088 34.17 34.17 0 01-34.089 34.089zm0 136.354h-344.28a34.088 34.088 0 110-68.177h344.28a32.337 32.337 0 0134.089 34.088 34.17 34.17 0 01-34.089 34.089zM836.554 975.791H280.926A133.12 133.12 0 01147.995 842.86a130.991 130.991 0 01132.931-132.932h555.628a34.088 34.088 0 110 68.177H280.926a64.674 64.674 0 00-64.673 64.674 62.68 62.68 0 0064.673 64.674h555.628a32.337 32.337 0 0134.089 34.088 34.17 34.17 0 01-34.089 34.25z"/>'
  }
})
