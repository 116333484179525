export default {
  titleKeyWordSearch: '标题关键字搜索',
  publishTimeStartRange: '发布时间（开始范围）',
  publishTimeEndRange: '发布时间（结束范围）',
  country: '国家',
  newsTitle: '新闻标题',
  publishTime: '发布时间',
  haveRead: '已读',
  unread: '未读',
  school: '学校',
  appendix: '附件 appendix',
}
