export default {
  loginTitle: 'ISSUE 登录',
  login: '立即登录',
  placehdUsern: '请输入手机号',
  placehdPwd: '请输入密码',
  rememberPwd: '记住密码',
  forgetLoginInfo: '忘记密码',
  noAccountMsg: '还没有账号?',
  noAccountMsg2: '', // 占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位我占占占

  registerTitle: 'ISSUE 注册',
  register: '立即注册',
  invitationCode: '邀请码',
  invitationCodeMsg: '请输入邀请码',
  mobile: '手机号',

  mobileAreaCode: '区号',
  verification: '验证码',
  getVerification: '获取验证码',
  confirmPassword: '确认密码',
  mobileAreaCodeMsg: '请选择区号',
  mobileMsg: '请输入手机号',
  verificationMsg: '请输入验证码',
  nextStep: '下一步',
  lastStep: '上一步',
  applyPeopleName: '申请人姓名',
  applyPeopleNameMsg: '请输入申请人姓名',
  company: '院校/机构名',
  companyMsg: '请输入院校/机构名',
  country: '国家或地区',
  state: '州省',
  city: '城市',
  address: '联系地址',
  addressMsg: '请输入联系地址',
  zipCode: '邮政编码',
  hasAccountMsg: '已经拥有账户?',
  hasAccountMsg2: '', // 占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位我占占占

  password: '密码',
  validPwdNum: '密码长度不能小于8位',
  validPwdMsg: '两次输入密码不一致',
  validUser: '请输入正确的手机号',
  loginVerification: '验证失败，请重新登录',
  noInvitationCode: '用户未填写邀请码',
  invalidInvitationCode: '邀请码无效',
  userPawdError: '用户或密码错误！',
  resetPassword:'重置密码',
  oldpassword: '请输入旧密码',
  newpassword: '请输入新密码',
  confirmpassword: '确认密码',
  confirmreset: '确认重置',
  outReset: '退出重置',
  isOut: '是否退出重置密码',
  operaConfirmTips: '操作确认',
  confirm: '确定',
  cancel: '取消',
  cancelOut: '取消退出',
  twopsdNotConsistent: '两次输入密码不一致!',
  passwordResetFailed:'重置密码失败',
  passwordResetSuccessful:'重置密码成功，请重新登录',
};
