/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'unfilled': {
    width: 16,
    height: 16,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M850.538 895.517c-11.494 0-22.988-4.387-31.763-13.162L141.104 204.669c-17.549-17.534-17.549-45.992 0-63.525 17.548-17.55 45.977-17.55 63.526 0L882.3 818.829c17.55 17.535 17.55 45.993 0 63.526-8.773 8.774-20.268 13.162-31.762 13.162z" _fill="#333"/><path pid="1" d="M172.867 895.517c-11.495 0-22.988-4.387-31.763-13.162-17.549-17.534-17.549-45.993 0-63.526l677.67-677.685c17.55-17.55 45.978-17.55 63.527 0 17.548 17.534 17.548 45.992 0 63.525L204.63 882.355c-8.774 8.774-20.27 13.162-31.763 13.162z" _fill="#333"/>'
  }
})
