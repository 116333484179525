export default {
  loginTitle: 'ISSUE Login',
  login: 'Login',
  placehdUsern: 'Please enter the phone No.',
  placehdPwd: 'Please enter the password',

  rememberPwd: 'Keep Password',
  forgetLoginInfo: 'Lost Password',
  noAccountMsg: 'Don\'t have an account yet?',
  noAccountMsg2: '', // 占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位我占占占

  registerTitle: 'ISSUE Register',
  register: 'register',
  invitationCode: 'Invitation Code',
  invitationCodeMsg: 'please entry the invitation code',
  mobile: 'Phone No.',

  mobileAreaCode: 'Area Code',
  verification: 'Verification Code',
  getVerification: 'Get Code',
  confirmPassword: 'Confirm Password',
  mobileAreaCodeMsg: 'Please select the area code',
  mobileMsg: 'Please entry your Phone No.',
  verificationMsg: 'please entry the verification Code',
  nextStep: 'Next Step',
  lastStep: 'Last Step',
  applyPeopleName: 'Name of applicant',
  applyPeopleNameMsg: 'Please entry the name of applicant',
  company: 'Name of School/institution',
  companyMsg: 'Please entry the name of School or institution',
  country: 'country or region',
  state: 'State',
  city: 'City',
  address: 'Contact Address',
  addressMsg: 'Please entry the Contact address',
  zipCode: 'Postal Code',
  hasAccountMsg: 'Have an account already?',
  hasAccountMsg2: '', // 占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位我占占占
  password: 'Password',
  validPwdNum: 'The password can not be less than 8 digits',
  validPwdMsg: 'The two passwords your entry are inconsistent',
  validUser: 'Please enter the correct Phone No.',
  loginVerification: 'Verification failed, please Login again.',
  noInvitationCode: 'Did not fill in invitation code',
  invalidInvitationCode: 'Invalid InvitationCode',
  userPawdError: 'User or Password error!',

  resetPassword:'Reset password',
  oldpassword: 'Please enter the old password',
  newpassword: 'Please enter a new password',
  confirmpassword: 'Confirm password',
  confirmreset: 'Confirm reset',
  outReset: 'Exit reset',
  isOut: 'Whether to exit and reset password',
  operaConfirmTips: 'Operation confirmation',
  confirm: 'OK',
  cancel: 'cancel',
  cancelOut: 'Cancel out',
  twopsdNotConsistent: 'The password entered twice is inconsistent!',
  passwordResetFailed:'Password reset failed',
  passwordResetSuccessful:'Password reset successful, please log in again',
};
