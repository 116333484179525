
import { Component, Vue } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import { SettingsModule } from '@/store/modules/settings';
import { PermissionModule } from '@/store/modules/permission'
import SidebarItem from './SidebarItem.vue';
import SidebarLogo from './SidebarLogo.vue';
import variables from '@/styles/_variables.scss';

@Component({
  name: 'SideBar',
  components: {
    SidebarItem,
    SidebarLogo,
  },
})
export default class extends Vue {
  get sidebar() {
    return AppModule.sidebar;
  }

  get routes() {
    return PermissionModule.viewRouters;
  }

  get showLogo() {
    return SettingsModule.showSidebarLogo;
  }

  get menuActiveTextColor() {
    if (SettingsModule.sidebarTextTheme) {
      return SettingsModule.theme;
    } else {
      return variables.menuActiveText;
    }
  }

  get variables() {
    return variables;
  }

  get activeMenu() {
    const route = this.$route;
    const { meta, path }: any = route;
    // if set path, the sidebar will highlight the path you set
    if (meta.activeMenu) {
      return meta.activeMenu;
    }
    return path;
  }

  get isCollapse() {
    return !this.sidebar.opened;
  }
}
