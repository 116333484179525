import login from './login';
import route from './route';
import common from './common';
import layout from './layout';
import stuManagement from './student_management';
import validate from './validate';
import message from './message';
import newsManagement from './news_management'

export default {
  index: {
    enrolledSuccessProportion: '{text}/总学生数',
    studentTotalSum: '学生数（申请数）',
    applyStatus: '申请状态',
    applyCount: '申请数',
    studentCount: '学生数',
    year: '{year}年',
    nowStudentCount: '{year}年学生数',
    nowReadingCount: '{year}年{text}',
    allStudentCount: '全部学生数',
    allReadingCount: '全部{text}',
    tNowStudentCount: '{year}年学生数: {num}',
    tNowReadingCount: '{year}年{text}: {num}',
    tAllStudentCount: '全部学生数: {num}',
    tAllReadingCount: '全部{text}: {num}',
    applicationCountBarTitle: '申请学生数/{text}',
    countryDistributionPieTitle: '学生申请意向国家/地区分布',
    enrolledSuccess: '成功入读数',
    osCount: 'OS数',
  },
  login,
  route,
  common,
  message,
  ...layout,
  stuManagement,
  validate,
  newsManagement,
};
