/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'home': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M291.465 81.385c-73.728-4.278-143.679 33.724-181.817 98.76a209.806 209.806 0 000 211.444c38.138 65.035 108.089 103.037 181.817 98.76h151.643c26.123-.365 47.195-22.074 47.559-48.925V286.23c-.364-113.004-89.339-204.482-199.202-204.8zM149.333 286.322a136.76 136.76 0 01136.534-136.943c75.412 0 136.533 61.303 136.533 136.988v136.079H285.867a136.76 136.76 0 01-136.534-136.079zm292.41 249.856H285.866a204.8 204.8 0 10204.8 204.8V585.102a49.607 49.607 0 00-48.925-48.924zm-19.343 204.8a136.533 136.533 0 11-136.533-136.534H422.4v136.534zm324.494 204.8c111.639-6.144 198.884-96.029 198.884-204.8s-87.245-198.656-198.884-204.8h-160.38c-27.626.364-49.972 22.073-50.336 49.015V740.66c.364 113.14 94.526 204.8 210.716 205.119zm90.34-107.953a136.533 136.533 0 01-232.79-97.666V604.444h136.534a136.533 136.533 0 0196.256 233.381zM740.978 490.667a204.8 204.8 0 10-204.8-204.8v155.875a49.607 49.607 0 0048.924 48.925zm5.552-68.267H604.444V285.412c.456-75.276 63.944-136.079 142.086-136.079 73.956 5.553 130.981 65.036 130.981 136.534 0 71.452-57.025 130.98-130.98 136.533z"/>'
  }
})
