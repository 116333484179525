/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'address': {
    width: 200,
    height: 200,
    viewBox: '0 0 1025 1024',
    data: '<path pid="0" d="M365.063 704h-147.2c-19.2 0-44.8 12.8-51.2 25.6l-160 204.8c-12.8 12.8-6.4 25.6 12.8 25.6h985.6c19.2 0 25.6-12.8 12.8-25.6l-160-204.8c-12.8-12.8-32-25.6-51.2-25.6h-147.2l-51.2 64h198.4l102.4 128h-793.6l102.4-128h198.4l-51.2-64z"/><path pid="1" d="M512.003 64c-140.8 0-256 115.2-256 256 0 44.8 12.8 89.6 32 128l160 275.2c38.4 64 96 64 128 0l147.2-262.4c25.6-38.4 44.8-89.6 44.8-140.8 0-140.8-115.2-256-256-256zm160 352l-128 230.4c-25.6 44.8-38.4 44.8-64 0l-134.4-236.8c-19.2-25.6-25.6-57.6-25.6-96 0-102.4 83.2-185.6 192-185.6s192 83.2 192 185.6c0 38.4-12.8 70.4-32 102.4z"/>'
  }
})
