/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'down-arrow': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M386.73 421.888l126.635 126.669 126.772-126.703 31.675 31.71-158.378 158.378-158.38-158.378z"/>'
  }
})
