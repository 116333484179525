/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'filled': {
    width: 16,
    height: 16,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M346.3 843.402c-16.571 0-27.618-5.524-38.664-16.57L31.467 550.664c-22.093-22.094-22.093-55.234 0-77.328s55.234-22.093 77.327 0L346.3 710.841l568.907-568.906c22.093-22.094 55.233-22.094 77.327 0s22.093 55.233 0 77.327l-607.57 607.57c-11.047 11.046-22.094 16.57-38.664 16.57z"/>'
  }
})
