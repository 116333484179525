export const isExternal = (path: string) => {
  return /^(https?:|mailto:|tel:)/.test(path);
};

export const hasChinese = (value: string) => {
  return /[\u4e00-\u9fa5。，！？·【】—《》‘’“”、；：]+/.test(value)
};
// 相对严谨的邮件校验
export const checkEmail2 = (email: string) => {
  // const regEmail: RegExp = /^([a-zA-Z0-9_-])+@([a-zA-Z-0-9_-])+(\.[a-zA-Z0-9_-])+/
  // const regEmail: RegExp = /^\w+(?:\.\w+){0,1}@[a-zA-Z0-9]{2,14}(?:\.[a-z]{2,4}){1,2}$/;
  const regEmail = /^[a-zA-Z0-9_.-]+@[a-zA-Z-0-9-]+\.{0,1}[a-zA-Z-0-9-]+\.{0,1}[a-zA-Z0-9-]*\.[a-zA-Z0-9.]{2,20}$/;
  return regEmail.test(email)
};
// 检查邮箱是否多个// 根据;或空格进行分割填写多个邮箱
export const checkEmailList = (email: string)=> {
  let val: any=[]
  if(email) {
    // 根据;或空格进行分割填写多个邮箱
    const emailList: any=email.trim().split(/[(;)][(\s)]/);
    if(emailList&&emailList.length>1) {
      val=emailList;
    } else {
      val=emailList;
    }
  }
  return val
};
