
import { Component, Vue } from 'vue-property-decorator';
import { SettingsModule } from '@/store/modules/settings';

@Component({
  name: 'Settings',
})
export default class extends Vue {
  get fixedHeader() {
    return SettingsModule.fixedHeader;
  }

  set fixedHeader(value) {
    SettingsModule.ChangeSetting({ key: 'fixedHeader', value });
  }

  get showSidebarLogo() {
    return SettingsModule.showSidebarLogo;
  }

  set showSidebarLogo(value) {
    SettingsModule.ChangeSetting({ key: 'showSidebarLogo', value });
  }

}
