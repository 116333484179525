/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'mobile': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M517.186 900.125c24.638 0 44.708-20.003 44.708-45.45 0-24.547-20.07-44.55-44.708-44.55-24.637 0-45.607 20.003-45.607 44.55.9 25.448 20.97 45.45 45.607 45.45zM730.464 62H293.536c-51.12 0-92.025 39.983-92.025 90v720c0 49.995 40.905 90 92.025 90h436.928c51.12 0 92.025-40.005 92.025-90V152c0-50.017-40.905-90-92.025-90zm1.012 855H302.671c-24.637 0-44.707-20.003-44.707-45.45V765.125h519.142V871.55c-.922 24.547-20.992 45.45-45.63 45.45zm34.56-214.2H257.964v-540c0-24.548 19.62-44.55 43.672-44.55h418.95c24.075 0 43.673 20.002 43.673 44.55v540h1.777z"/>'
  }
})
