/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'news-info': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path d="M832 192v640H192V192h640m32-64H160c-17.6 0-32 14.4-32 32v704c0 17.6 14.4 32 32 32h704c17.6 0 32-14.4 32-32V160c0-17.6-14.4-32-32-32zM768 640H576v64h192v-64zM512 512H256v192h256V512z m256 0H576v64h192v-64z m0-192H256v128h512V320z" p-id="2298"></path>'
  }
})
