/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'video-center': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M878.705 885.048H754.98A445.102 445.102 0 10512 957.44h366.71a36.198 36.198 0 10-.005-72.392zM138.957 512A373.048 373.048 0 11512 885.048 373.468 373.468 0 01138.957 512z"/><path pid="1" d="M448.307 674.304a50.555 50.555 0 01-50.596-50.596V407.593a50.601 50.601 0 0175.889-43.837l187.105 108.057a50.627 50.627 0 010 87.66L473.6 667.525a50.606 50.606 0 01-25.293 6.779zm21.576-229.32v141.348l122.368-70.656zm172.78 83.246zm-18.054-31.257z"/>'
  }
})
