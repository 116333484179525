export default {
  titleKeyWordSearch: '標題關鍵字搜索',
  publishTimeStartRange: '發佈時間（開始範圍）',
  publishTimeEndRange: '發佈時間（結束範圍）',
  country: '國家',
  newsTitle: '新聞標題',
  publishTime: '發佈時間',
  haveRead: '已讀',
  unread: '未讀',
  school: '學校',
  appendix: '附件 appendix',
}
