export default {
  loginTitle: 'ISSUE 登錄',
  login: '立即登錄',
  placehdUsern: '請輸入手機號',
  placehdPwd: '請輸入密碼',
  rememberPwd: '記住密碼',
  forgetLoginInfo: '忘記密碼',
  noAccountMsg: '還沒有賬號?',
  noAccountMsg2: '', // 占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位我占占占

  registerTitle: 'ISSUE 註冊',
  register: '立即註冊',
  invitationCode: '邀請碼',
  invitationCodeMsg: '請輸入邀請碼',
  mobile: '手機號',

  mobileAreaCode: '區號',
  verification: '驗證碼',
  getVerification: '獲取驗證碼',
  confirmPassword: '確認密碼',
  mobileAreaCodeMsg: '請選擇區號',
  mobileMsg: '請輸入手機號',
  verificationMsg: '請輸入驗證碼',
  nextStep: '下一步',
  lastStep: '上一步',
  applyPeopleName: '申請人姓名',
  applyPeopleNameMsg: '請輸入申請人姓名',
  company: '院校/機構名',
  companyMsg: '請輸入院校/機構名',
  country: '國家或地區',
  state: '州省',
  city: '城市',
  address: '聯系地址',
  addressMsg: '請輸入聯系地址',
  zipCode: '郵政編碼',
  hasAccountMsg: '已經擁有賬戶?',
  hasAccountMsg2: '', // 占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位我占占占

  password: '密碼',
  validPwdNum: '密碼長度不能小於8位',
  validPwdMsg: '兩次輸入密碼不一致',
  validUser: '請輸入正確的手機號',
  loginVerification: '驗證失敗，請重新登錄',
  noInvitationCode: '用戶未填寫邀請碼',
  invalidInvitationCode: '邀請碼無效',
  userPawdError: '用戶或密碼錯誤！',
  resetPassword:'重置密碼',
  oldpassword: '請輸入舊密碼',
  newpassword: '請輸入新密碼',
  confirmpassword: '確認密碼',
  confirmreset: '確認重置',
  outReset: '退出重置',
  isOut: '是否退出重設密碼',
  operaConfirmTips: '操作確認',
  confirm: '確定',
  cancel: '取消',
  cancelOut: '取消退出',
  twopsdNotConsistent: '兩次輸入密碼不一致!',
  passwordResetFailed:'重置密碼失敗',
  passwordResetSuccessful:'重設密碼成功，請重新登入',
};
