/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'commission-management': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M514.4 502.4c-133.44 0-258.24-27.36-342.72-75.36C81.92 376.64 63.2 318.56 63.2 279.2c0-29.76 10.56-58.08 31.2-84.48C159.68 110.24 324.8 56 514.4 56s354.72 54.24 420 138.72c20.64 26.4 31.2 54.72 31.2 84.48 0 39.36-18.72 97.44-108.48 147.84-18.24 10.56-41.76 3.84-52.32-14.4-10.56-18.24-3.84-41.76 14.4-52.32 44.64-25.44 69.6-54.24 69.6-81.12 0-12-4.8-24.48-14.88-37.44-40.8-52.8-170.88-108.96-359.52-108.96s-318.72 56.16-359.52 108.96C144.8 254.24 140 266.72 140 279.2c0 26.88 24.48 55.68 69.6 81.12 72 40.8 186.24 65.28 304.8 65.28 48 0 94.56-3.84 138.72-11.04 21.12-3.36 40.8 10.56 44.16 31.68 3.36 21.12-10.56 40.8-31.68 44.16-48 7.68-98.88 12-151.2 12z"/><path pid="1" d="M927.2 546.56c-21.12 0-38.4-17.28-38.4-38.4V279.2c0-21.12 17.28-38.4 38.4-38.4s38.4 17.28 38.4 38.4v229.44c0 20.64-17.28 37.92-38.4 37.92zm-825.6 0c-21.12 0-38.4-17.28-38.4-38.4V279.2c0-21.12 17.28-38.4 38.4-38.4s38.4 17.28 38.4 38.4v229.44c0 20.64-17.28 37.92-38.4 37.92z"/><path pid="2" d="M514.4 960.8c-147.36 0-285.12-34.08-367.68-90.72C77.6 822.56 63.2 771.68 63.2 737.6V508.16c0-21.12 17.28-38.4 38.4-38.4s38.4 17.28 38.4 38.4c0 26.88 24.48 55.68 69.6 81.12 73.44 41.28 184.32 65.28 304.8 65.28s231.84-24 304.8-65.28c44.64-25.44 69.6-54.24 69.6-81.12 0-21.12 17.28-38.4 38.4-38.4s38.4 17.28 38.4 38.4V737.6c0 34.56-14.4 85.44-83.52 132.48-82.56 56.64-220.32 90.72-367.68 90.72zM140 635.84V737.6c0 30.24 31.2 56.16 49.92 69.12C259.52 854.24 383.84 884 514.4 884c130.56 0 254.88-29.76 324.48-77.28 18.72-12.96 49.92-38.88 49.92-69.12V636.32c-9.6 6.72-20.16 13.44-31.68 20.16-84.48 48-209.28 75.36-342.72 75.36S256.16 704 171.68 656c-11.52-6.24-22.08-12.96-31.68-20.16z"/>'
  }
})
