export default {
  titleKeyWordSearch: 'Title Keyword Search',
  publishTimeStartRange: 'Publish time (start range)',
  publishTimeEndRange: 'Publish time (end range)',
  country: 'country',
  newsTitle: 'News Title',
  publishTime: 'Publish time',
  haveRead: 'read',
  unread: 'unread',
  school: 'school',
  appendix: 'appendix',
}
