import { RouteConfig } from 'vue-router';
import Layout from '@/layout/index.vue';

const studentManagement: RouteConfig[] = [
  {
    path: '/student-management',
    component: Layout,
    meta: {
      icon: 'student-management',
      title: 'studentManagement',
      alwaysShow: true,
    },
    children: [
      {
        path: 'student-register',
        name: 'StudentRegister',
        meta: {
          hidden: true,
          title: 'studentRegister',
          activeMenu: '/student-management/student-info-management',
        },
        // tslint:disable:max-line-length
        component: () => import(/* webpackChunkName: "stuManagement" */ '@/views/student-management/StudentRegister.vue'),
      },
      {
        path: 'student-info/:stuid',
        name: 'StudentInfo',
        meta: {
          title: 'studentInfo',
          hidden: true,
          activeMenu: '/student-management/student-info-management',
        },
        // tslint:disable:max-line-length
        component: () => import(/* webpackChunkName: "stuManagement" */ '@/views/student-management/StudentInfo.vue'),
      },
      {
        path: 'student-application-form/:stuid',
        name: 'StudentApplicationForm',
        meta: {
          title: 'studentApplicationForm',
          hidden: true,
          activeMenu: '/student-management/student-info-management',
        },
        // tslint:disable:max-line-length
        component: () => import(/* webpackChunkName: "stuManagement" */ '@/views/student-management/StudentApplicationForm.vue'),
      },
      {
        path: 'student-info-management',
        name: 'studentInfoManagement',
        meta: {
          title: 'studentInfoManagement',
        },
        // tslint:disable:max-line-length
        component: () => import(/* webpackChunkName: "stuManagement" */ '@/views/student-management/StudentInfoManagentment.vue'),
      },
      // {
      //   path: 'student-application-summary',
      //   name: 'studentApplicationSummary',
      //   meta: {
      //     title: 'studentApplicationSummary',
      //   },
      //   // tslint:disable:max-line-length
      //   component: () => import(/* webpackChunkName: "stuManagement" */ '@/views/student-management/StudentApplicationSummary.vue'),
      // },
    ],
  },
];

export default studentManagement;
