/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'general-table': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M891.781 87.781H699.875c-18.094 0-32.813 14.719-32.813 32.813s14.72 32.812 32.813 32.812h159.188v159.188c0 18.094 14.718 32.812 32.812 32.812s32.813-14.719 32.813-32.812v-192c-.094-18.094-14.72-32.813-32.907-32.813zM132.22 345.406c18.094 0 32.812-14.719 32.812-32.812V153.406H324.22c18.094 0 32.812-14.719 32.812-32.812S342.312 87.78 324.22 87.78h-192c-18.094 0-32.813 14.719-32.813 32.813v192c0 18.094 14.625 32.812 32.813 32.812zm191.906 525.188H165.031V711.406c0-18.093-14.719-32.812-32.812-32.812s-32.813 14.718-32.813 32.812v192c0 18.094 14.719 32.813 32.813 32.813h192c18.094 0 32.812-14.719 32.812-32.813s-14.719-32.812-32.906-32.812zm567.656-192c-18.093 0-32.812 14.718-32.812 32.812v159.188H699.875c-18.094 0-32.813 14.718-32.813 32.812s14.72 32.813 32.813 32.813h192c18.094 0 32.813-14.719 32.813-32.813v-192c-.094-18.093-14.72-32.812-32.907-32.812zm-138.75 98.437c8.625 0 17.25-3.375 23.719-10.125 12.469-13.125 12-33.843-1.125-46.406l-82.688-78.938c24.657-35.156 40.313-77.25 43.125-123.28 8.438-132.75-92.53-247.22-225.28-255.563-5.157-.281-10.22-.469-15.282-.469-126.188 0-232.219 98.25-240.281 225.938-8.344 132.843 92.625 247.218 225.375 255.562 5.156.281 10.219.469 15.281.469 57.469 0 110.719-20.344 152.438-54.563l82.03 78.282c6.376 6.093 14.532 9.093 22.688 9.093zM670.625 514.25c-2.813 44.813-22.406 86.344-55.125 117-32.625 30.563-75.094 47.344-119.625 47.344-3.75 0-7.5-.094-11.25-.375-46.781-2.907-89.531-23.907-120.563-59.063-30.937-35.156-46.406-80.25-43.5-126.937 2.813-44.813 22.407-86.344 55.125-117 32.625-30.563 75.094-47.344 119.625-47.344 3.75 0 7.5.094 11.157.375 46.781 2.906 89.531 23.906 120.562 59.063 31.125 35.062 46.594 80.156 43.594 126.937z"/>'
  }
})
