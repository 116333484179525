
import { Component, Vue } from 'vue-property-decorator';
import { ErrorLogModule } from '@/store/modules/error_log';

@Component({
  name: 'ErrorLog',
})
export default class extends Vue {
  private dialogTableVisible = false;

  get errorLogs() {
    return ErrorLogModule.logs;
  }

  private clearAll() {
    this.dialogTableVisible = false;
    ErrorLogModule.ClearErrorLog();
  }
}
