/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'export': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M315.88 726.877a76.117 76.117 0 10107.644-107.648A76.117 76.117 0 10315.88 726.877zM893.952 67.072H591.076c-16.043 0-31.63 19.342-31.63 35.385s15.587 36.636 31.63 36.636h237.226l-362.61 355.1c-11.15 11.606-9.898 39.14 1.707 50.29 11.264 10.923 40.846 9.33 52.11-1.592l359.766-347.136v217.543c0 16.042 19.342 29.127 35.498 29.127 16.043 0 35.385-12.97 35.385-29.127V123.164c-.114-30.947-25.258-56.092-56.206-56.092z"/><path pid="1" d="M898.73 522.638c-21.503 0-38.911 17.408-38.911 38.912v215.837c0 49.265-40.164 89.429-89.43 89.429H241.323c-49.266 0-89.43-40.164-89.43-89.43V248.435c0-49.266 40.164-89.43 89.43-89.43h203.662c21.504 0 38.912-17.408 38.912-38.912s-17.408-39.025-39.026-39.025H241.21c-92.16 0-167.253 75.093-167.253 167.367v528.953c0 92.273 75.093 167.367 167.367 167.367h528.953c92.273 0 167.367-75.094 167.367-167.367V561.55c0-21.504-17.408-38.912-38.912-38.912z"/>'
  }
})
