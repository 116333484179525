/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'user': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M605.525 972.97h-183.09c-178.074 0-318.806 0-318.806-97.825v-17.75c0-172.612 143.018-313.037 318.805-313.037H605.56c175.65 0 318.635 140.425 318.635 313.003v17.818c-.034 97.792-147.66 97.792-318.635 97.792zm-183.09-358.809c-137.251 0-248.97 109.124-248.97 243.2v17.818c0 28.023 168.141 28.023 248.97 28.023h183.124c61.543 0 248.832 0 248.832-27.99v-17.85c0-134.077-111.616-243.2-248.832-243.2H422.434zm82.295-71.782c-140.357 0-254.6-112.265-254.6-250.198 0-137.898 114.243-250.163 254.634-250.163 140.356 0 254.566 112.23 254.566 250.197s-114.21 250.198-254.6 250.198zM320 292.18c0 99.465 82.91 180.395 184.798 180.395s184.764-80.896 184.764-180.36c0-99.465-82.876-180.361-184.764-180.361S320 192.785 320 292.215z"/>'
  }
})
