export default {
  index: '申請概要',
  studentManagement: '代理學生管理',
  studentRegister: '學生註冊',
  studentInfo: '學生個人信息',
  studentInfoManagement: '學生資料管理',
  studentApplicationSummary: '學生申請匯總',
  commissionManagement: '傭金管理',
  informationCenter: '資訊中心',
  newsInfo: '新聞資訊',
  institutionInfo: '院校資訊',
  videoCenter: '視頻中心',
  newsInfoEntrance: '新聞資訊入口',
  newsInfoDetail: '新聞資訊詳情',
  quickViewMPS: '快速查看MPS'
};
