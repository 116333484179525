/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'checkstudentinfo': {
    width: 32,
    height: 32,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M531 510.08a363.81 363.81 0 01118.65 19.74 25 25 0 0016.27-47.28 411 411 0 00-41.84-12c71.47-34.44 120.9-107.61 120.9-192.1 0-117.51-95.6-213.11-213.11-213.11S318.73 161 318.73 278.48c0 84.26 49.16 157.25 120.3 191.81A414.33 414.33 0 00116.37 874v41.76a25 25 0 0025 25h335.21a25 25 0 000-50H166.37V874c.35-200.65 163.91-363.92 364.63-363.92zm-162.27-231.6c0-89.94 73.16-163.11 163.1-163.11s163.11 73.17 163.11 163.11-73.17 163.1-163.11 163.1-163.1-73.17-163.1-163.1z"/><path pid="1" d="M547.43 601.79a25 25 0 0025 25h292.23a25 25 0 000-50H572.43a25 25 0 00-25 25zm317.23 121.12H572.43a25 25 0 000 50h292.23a25 25 0 000-50zm0 129.51H572.43a25 25 0 000 50h292.23a25 25 0 000-50z"/>'
  }
})
