/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'zip-code': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M809.811 74.356H203.608c-99.103 0-176.959 76.252-176.959 173.323 0 55.457 49.563 103.958 106.193 103.958h424.661c21.27 0 35.428-13.864 35.428-34.66 0-20.796-14.157-34.683-35.428-34.683h-424.66c-21.248 0-35.383-13.864-35.383-34.637 0-55.48 49.54-104.003 106.17-104.003h606.204c56.585 0 106.193 48.524 106.193 104.003V772.73c0 55.434-49.608 103.981-106.193 103.981H203.608c-56.63 0-106.17-48.547-106.17-103.98V524.982c0-20.796-14.181-34.682-35.384-34.682-21.247 0-35.405 13.886-35.405 34.682v247.793c0 97.07 77.878 173.3 176.959 173.3h606.203c99.126 0 176.937-76.23 176.937-173.3V247.679c0-97.07-77.833-173.323-176.937-173.323zM415.95 559.62c21.225 0 35.382-13.864 35.382-34.66 0-20.796-14.135-34.683-35.382-34.683H274.373c-21.247 0-35.382 13.887-35.382 34.683 0 20.773 14.135 34.66 35.382 34.66H415.95zm323.073 178.494c21.203 0 35.406-13.864 35.406-34.637 0-20.841-14.203-34.706-35.406-34.706H628.291c-21.202 0-35.337 13.865-35.337 34.706 0 20.773 14.135 34.637 35.337 34.637h110.732z"/>'
  }
})
