import { AnyObj } from '@/interface/common';
import { MessageBox } from 'element-ui';
import i18n from '@/lang';
import { fileDownloadApi } from '@/api/news'
/**
 * 防抖函数  在一定时间间隔内限制触发函数执行
 * @param fn 需要执行的函数
 * @param wait 等待时间
 * @param immediate false先等待再执行,true先执行再等待
 * @returns 返回一个函数
 */
export function debounce(fn: any, wait: number, immediate = false) {
  // timer 定时函数返回唯一ID; startTimeStamp 计时起始时间
  // context 函数调用上下文; args 要执行的函数传递的参数
  let timer: any;
  let startTimeStamp = 0;
  let context: any;
  let args: any;
  const run = (timeout: number) => {
    timer = setTimeout(() => {
      const now = Date.now();
      const interval = now - startTimeStamp;
      if (interval < timeout) {
        startTimeStamp = now;
        run(wait - interval);
      } else {
        if (!immediate) {
          fn.apply(context, args)
        }
        clearTimeout(timer);
        timer = null;
      }
    }, timeout)
  }
  return function() {
    // @ts-ignore
    context = this;
    args = arguments;
    const now = Date.now();
    startTimeStamp = now;
    if (!timer) {
      if (immediate) {
        fn.appply(context, args)
      }
      run(wait);
    }
  }
}

/**
 * 节流函数
 */

export function getOffsetTop(elem: any) {
  return elem.offsetTop;
}

export function getOffsetLeft(elem: any) {
  return elem.offsetLeft;
}

// 文本内容换行
export const wrap = (text: any): any => {
  if (text && typeof text === 'string') {
    return text.replace(/\n/g, '<br>');
  } else {
    return text;
  }
}

// 下拉检索方法
export const remoteFun = (query: any, list: any, keyName?: any) => {
  let institutionListOptions: any = [];
  if (query !== '') {
    // 多个字段进行筛选
    if (keyName instanceof Array && keyName.length) {
      for (const v of keyName) {
        const keyArr = list.filter((item: any) => {
          return item[v] && item[v].toLowerCase().indexOf(query.toLowerCase()) > -1;
        });
        institutionListOptions = institutionListOptions.concat(keyArr);
        institutionListOptions = Array.from(new Set(institutionListOptions))
      }
    } else {
      // 默认这种
      let key: any = 'fullName';
      if (keyName) {
        key = keyName;
        institutionListOptions = list.filter((item: any) => {
          return item[key] && item[key].toLowerCase().indexOf(query.toLowerCase()) > -1;
        });
      } else {
        institutionListOptions = [];
      }
    }
  } else {
    institutionListOptions = [];
  }
  return institutionListOptions
}

export function getCurYear() {
  return new Date().getFullYear();
}

// 用于远程检索后 需要回显的方法
export const searchEcho = (list: any, id: any, type?: string) => {
  const arr: any = [];
  if (type === 'arr') {
    if (list && list.length > 0) {
      for (const key of list) {
        if (id) {
          if (id.includes(key.id)) {
            arr.push(key);
          }
        }
      }
    }
  } else {
    if (list && list.length > 0) {
      for (const key of list) {
        if (key.id === id) {
          arr.unshift(key);
        } else {
          arr.push(key);
        }
      }
    }
  }
  return arr;
}

// 是否有值
export const hasVal = (value: any): boolean => {
  if (value === undefined || value === null) {
    return false;
  }

  if (typeof value === 'string' && !value.trim()) {
    return false;
  }

  if (typeof value === 'object') {
    const check = Object.prototype.toString;
    if (check.call(value) === '[object Array]' && !value.length) {
      return false;
    }
    if (check.call(value) === '[object Object]' && !Object.keys(value).length) {
      return false;
    }
  }

  if (value !== 0 && !value) {
    return false;
  }
  return true;
}

/**
 * deepClone 深克隆
 * @param {AnyObj | any[]} source
 * @param {string[]} dDeep 不进行深克隆的属性
 * @returns {Object}
 */
export const deepClone = (source: AnyObj, dDeep: string[] = []) => {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments deepClone');
  }
  const targetObj: any[] | any = source.constructor === Array ? [] : {}
  Object.keys(source).forEach((keys) => {
    if (
      source[keys] &&
      typeof source[keys] === 'object' &&
      !dDeep.find((item) => item === source[keys])
    ) {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
};

// 复制到剪贴板
export const copyToClipboard = (text: string): void => {
  if (navigator.clipboard) {
      // clipboard api 复制
      navigator.clipboard.writeText(text);
  } else {
      const textarea = document.createElement('textarea');
      document.body.appendChild(textarea);
      // 隐藏此输入框
      textarea.style.position = 'fixed';
      textarea.style.clip = 'rect(0 0 0 0)';
      textarea.style.top = '10px';
      // 赋值
      textarea.value = text;
      // 选中
      textarea.select();
      // 复制
      document.execCommand('copy', true);
      // 移除输入框
      document.body.removeChild(textarea);
  }
}
// 是否是图片
export const isImage = (data: any): boolean => {
  const imgArr = ['.jpeg', '.jpg', '.png', '.gif', '.pdf'];
  let ret: boolean = false;
  imgArr.forEach((item: any) => {
    if (data && data.toLocaleLowerCase().indexOf(item) !== -1) {
      ret = true;
      return
    }
  })
  return ret;
}
// 附件图片/pdf浏览
export const checkImg = (fileKey: string, fileNameOrc: string): any => {
  const data = { fileNameOrc, fileKey }
  if (isImage(fileNameOrc)) {
    fileDownloadApi(data).then((res: any) => {
      if (res) {
        if (fileNameOrc.toLocaleLowerCase().indexOf('.pdf') !== -1) {
          const blob = new Blob([res.data], { type: 'application/pdf' });
          const elink = URL.createObjectURL(blob);
          window.open(elink);
        } else {
          let elink: any = '';
          const blob = new Blob([res.data]);
          elink = URL.createObjectURL(blob);
          MessageBox.confirm(
            '<p style="text-align:center;max-height:700px;overflow: auto"><img src="' + elink + '"></img><p/>',
            i18n.t('common.showImg') as string,
            {
              customClass: 'imgMessageBox',
              dangerouslyUseHTMLString: true,
              showClose: true,
              closeOnClickModal: true,
              showCancelButton: false,
              showConfirmButton: true,
              confirmButtonText: i18n.t('common.close') as string,
            }).catch(() => {
              const a = 1; // 屏蔽tslint报错
            })
        }
      }
    })
  }
}
