import Vue, { DirectiveOptions } from 'vue';
import 'common-ui/lib/common-ui.css';
import 'normalize.css';
import '@/styles/index.scss';

import ElementUI from '@/utils/element-ui-setting';
import '@/styles/element-variables.scss';

// 引入mso和issue公共插件
import CommonUI from 'common-ui/lib/common-ui.umd.min.js';

Vue.use(CommonUI);

// import * as directies from '@/directive';
// 自定义指令集
// Object.keys(directies).forEach((key) => {
//   Vue.directive(key, (directies as {[key: string]: DirectiveOptions})[key])
// })

import App from './App.vue';
import router from './router';
import store from './store';
import i18n from '@/lang/index';
import SvgIcon from 'vue-svgicon';
import toolStore from './utils/toolStore'

import '@/permission';
import '@/icons/components';
import '@/utils/class_component_hooks';

Vue.config.productionTip = false;

Vue.use(SvgIcon, {
  tagName: 'svg-icon',
});

Vue.use(ElementUI); // 按需引入element ui

new Vue({
  i18n,
  router,
  store,
  data: toolStore,
  render: (h) => h(App),
}).$mount('#app');
