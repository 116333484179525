
import { Component, Vue } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import { UserModule } from '@/store/modules/user';
import ErrorLog from '@/components/ErrorLog/index.vue';
import Hamburger from '@/components/Hamburger/index.vue';
import LangSelect from '@/components/LangSelect/index.vue';

@Component({
  name: 'Navbar',
  components: {
    ErrorLog,
    Hamburger,
    LangSelect,
  },
})
export default class extends Vue {
  get sidebar() {
    return AppModule.sidebar;
  }

  get userName() {
    return `${this.$t('navbar.webcomeBack')} ${UserModule.name || this.$t('navbar.noName')}`;
  }

  get company() {
    return UserModule.agentCompanyName;
  }

  public toggleSideBar() {
    AppModule.ToggleSideBar(true);
    const elem = document.querySelector('.stu-all-form-info');
    const navComp = document.querySelector('.nav-comp') as HTMLDivElement
    if (elem && navComp) {
      if (this.sidebar.opened === false) {
        navComp.style.left = 74 + 'px';
      } else {
        navComp.style.left = 250 + 'px';
      }
    }
  }

  public async logout() {
    await UserModule.LogOut().catch((err:any) => err);
    const toLoginData: any = ['newsInfoList', 'newsInfoDetail', 'StudentApplicationForm']
    if (toLoginData.includes(this.$route.name)) {
      this.$router.push(`/login`)
    } else {
      this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    }
  }
}
