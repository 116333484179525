import store from '@/store';
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators';
import { RouteConfig } from 'vue-router';
import { flatRoutes, asyncRoutes, constantRoutes } from '@/router';

const hasPermission = (roles: string[], route: RouteConfig) => {
  if (route.meta && route.meta.roles) {
    return roles.some((role:any) => (route.meta as any).roles.includes(role));
  } else {
    return true;
  }
};

export const filterAsyncRoutes = (routes: RouteConfig[], roles: string[]) => {
  const res: RouteConfig[] = [];
  routes.forEach((route) => {
    const r = { ...route };
    if (hasPermission(roles, r)) {
      if (r.children) {
        r.children = filterAsyncRoutes(r.children, roles);
      }
      res.push(r);
    }
  });
  return res;
};

export interface MyPermissionState {
  routes: RouteConfig[];
  dynamicRoutes: RouteConfig[];
}

@Module({ dynamic: true, store, name: 'permission' })
class Permission extends VuexModule implements MyPermissionState {
  public routes: RouteConfig[] = [];
  public dynamicRoutes: RouteConfig[] = [];
  public viewRouters: RouteConfig[] = [];

  @Action
  public GenerateRoutes(roles: string[]) {
    let accessedRoutes;
    let viewRouters;
    accessedRoutes = filterAsyncRoutes(flatRoutes, roles);
    viewRouters = filterAsyncRoutes(asyncRoutes, roles);
    this.SET_ROUTES(accessedRoutes);
    this.SET_VIEW_ROUTES(viewRouters);
  }

  @Mutation
  private SET_ROUTES(routes: RouteConfig[]) {
    this.routes = constantRoutes.concat(routes);
    this.dynamicRoutes = routes;
  }

  @Mutation
  private SET_VIEW_ROUTES(viewRouters: RouteConfig[]) {
    this.viewRouters = constantRoutes.concat(viewRouters);
  }
}

export const PermissionModule = getModule(Permission);
