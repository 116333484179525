/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'company': {
    width: 200,
    height: 200,
    viewBox: '0 0 1078 1024',
    data: '<path pid="0" d="M72.187 979.185a30.37 30.37 0 010-60.46h45.62V105.25a57.871 57.871 0 0154.77-60.434h561.284a57.871 57.871 0 0154.77 60.434v268.006h136.886a57.871 57.871 0 0154.745 60.434v483.55c0 .513 0 1-.128 1.487h23.066a30.37 30.37 0 010 60.46zm100.39-60.408h525.787a24.784 24.784 0 016.766-1.487h28.551V409.828a32.37 32.37 0 01-1.153-6.33 31.755 31.755 0 011.153-6.356V105.249H172.577zm615.926-1.487h136.886v-483.6H788.503zM302.595 654.307a30.37 30.37 0 010-60.434h294.302a30.37 30.37 0 010 60.434zm0-151.213a30.37 30.37 0 010-60.434h294.302a30.37 30.37 0 010 60.434z"/>'
  }
})
