import request from '@/utils/request_login';


// 获取新闻列表
export const getIssueNewsListApi = (data: any) => request({
  url: '/issue/news/getIssueNewsList',
  method: 'post',
  data
})

// 获取新闻详情
export const getIssueNewsDetailsApi = (fkNewsId: number, isRead: boolean) => request({
  url: '/issue/news/getIssueNewsDetails',
  method: 'get',
  params: { fkNewsId, isRead }
})

// 获取新闻模块的国家下拉
export const getBmsCountryUnconditionalApi = () => request({
  url: '/issue/studentCountry/getBmsCountryUnconditional',
  method: 'get',
})

// 文件下载
export function fileDownloadApi(data: any) {
  return request({
    url: '/appFile/download',
    method: 'post',
    data,
    responseType: 'blob',
  });
}
