/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'edit-form': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M725.333 938.667H270.08A139.947 139.947 0 01128 800.853v-550.4A139.947 139.947 0 01270.08 112.64h401.493a27.733 27.733 0 110 55.04H270.08a85.333 85.333 0 00-85.333 82.773v550.4a85.333 85.333 0 0085.333 82.774h455.253a85.333 85.333 0 0085.334-82.774V291.84a28.16 28.16 0 0128.586-27.733 27.733 27.733 0 0128.16 27.733v509.013a139.947 139.947 0 01-142.08 137.814zM526.08 415.573H355.413a27.733 27.733 0 110-55.04H526.08a27.733 27.733 0 110 55.04zM640 580.693H355.413a27.733 27.733 0 110-55.04H640a27.733 27.733 0 110 55.04zm0 165.12H355.413a27.733 27.733 0 110-55.04H640a27.733 27.733 0 110 55.04zm-7.253-378.88a27.307 27.307 0 01-27.734-25.6 26.027 26.027 0 018.107-18.773L847.36 95.573a29.013 29.013 0 0140.107 0 26.453 26.453 0 010 39.254L652.8 360.533a28.587 28.587 0 01-20.053 6.4z"/>'
  }
})
