/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'add-apply-course': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M882.6 325V135.8c0-39.6-32.2-71.8-71.8-71.8H213.2c-39.6 0-71.8 32.2-71.8 71.8v752.4c0 39.6 32.2 71.8 71.8 71.8h597.5c39.6 0 71.8-32.2 71.8-71.8V699c0-20.7-16.7-37.4-37.4-37.4s-37.4 16.7-37.4 37.4v186.1H416.1V138.8h391.6V325c0 20.7 16.7 37.4 37.4 37.4s37.5-16.8 37.5-37.4zM341.3 885.2h-125V138.8h125.1v746.4z"/><path pid="1" d="M669.6 697.7c20.7 0 37.4-16.7 37.4-37.4V549.4h110.9c20.7 0 37.4-16.7 37.4-37.4s-16.7-37.4-37.4-37.4H707V363.7c0-20.7-16.7-37.4-37.4-37.4s-37.4 16.7-37.4 37.4v110.9H521.3c-20.7 0-37.4 16.7-37.4 37.4s16.7 37.4 37.4 37.4h110.9v110.9c-.1 20.7 16.7 37.4 37.4 37.4z"/>'
  }
})
