
import { Component, Vue } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import { getSetLanguageCodeToCacheApi } from '@/api/login'
@Component({
  name: 'Login',
})
export default class extends Vue {
  get language() {
    return AppModule.language;
  }

  private async handleSetLanguage(lang: string) {
    this.$i18n.locale = lang;
    AppModule.SetLanguage(lang);
    document.documentElement.lang = lang;
    this.$message({
      message: this.$t('message.changeLanguageTips').toString(),
      type: 'success',
    });
    await getSetLanguageCodeToCacheApi(lang);
  }
}
