import { RouteConfig } from "vue-router";
import Layout from '@/layout/index.vue';

const newsManagement: RouteConfig[] = [
  {
    path: '/news',
    component: Layout,
    meta: {
      roles: ['newsRoot'],
    },
    children: [
      {
        path: 'news-info-list',
        name: 'newsInfoList',
        meta: {
          icon: 'news-info',
          title: 'newsInfoEntrance',
          noDot: true,
        },
        component: () => import('@/views/news-management/newsInfoList.vue')
      },
      {
        path: 'news-info-detail/:id',
        name: 'newsInfoDetail',
        meta: {
          title: 'newsInfoDetail',
          hidden: true,
          activeMenu: '/news/news-info',
        },
        component: () => import('@/views/news-management/newsInfoDetail.vue')
      },
    ]
  }
]
export default newsManagement
