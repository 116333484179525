import { Component, Vue} from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';

@Component({
  name: 'ResizeMixin',
})
export default class extends Vue {

  get sidebar() {
    return AppModule.sidebar;
  }
}
