import login from './login';
import route from './route';
import common from './common';
import layout from './layout';
import stuManagement from './student_management';
import validate from './validate';
import message from './message';
import newsManagement from './news_management'

export default {
  index: {
    enrolledSuccessProportion: '{text}/總學生數',
    studentTotalSum: '學生數（申請數）',
    applyStatus: '申請狀態',
    applyCount: '申請數',
    studentCount: '學生數',
    year: '{year}年',
    nowStudentCount: '{year}年學生數',
    nowReadingCount: '{year}年{text}',
    allStudentCount: '全部學生數',
    allReadingCount: '全部{text}',
    applicationCountBarTitle: '申請學生數/{text}',
    countryDistributionPieTitle: '學生申請意向國家/地區分布',
    enrolledSuccess: '成功入讀數',
    osCount: 'OS數',
  },
  login,
  route,
  common,
  message,
  ...layout,
  stuManagement,
  validate,
  newsManagement,
};
