export default {
  index: '申请概要',
  studentManagement: '代理学生管理',
  studentRegister: '学生注册',
  studentInfo: '学生个人信息',
  studentInfoManagement: '学生资料管理',
  studentApplicationSummary: '学生申请汇总',
  commissionManagement: '佣金管理',
  informationCenter: '资讯中心',
  newsInfo: '新闻资讯',
  institutionInfo: '院校资讯',
  videoCenter: '视频中心',
  newsInfoEntrance: '新闻资讯入口',
  newsInfoDetail: '新闻资讯详情',
  quickViewMPS: '快速查看MPS'
};
