export default {
  tip: '提示',
  loading: '加载数据中...',
  loginTimeout: '登录超时',
  errorMsg: '错误信息',
  reLogin: '重新登录',
  save: '保存',
  cancel: '取消',
  search: '搜索',
  reset: '重置',
  confirm: '确定',
  opera: '操作',
  download: '文件下载',
  yes: '是',
  no: '否',
  underSubmission: '提交中 ...',
  prevText: '上一页',
  nextText: '下一页',
  keyWord: '关键字',
  all: '全部',
  jan: '1月',
  feb: '2月',
  mar: '3月',
  apr: '4月',
  may: '5月',
  jun: '6月',
  jul: '7月',
  aug: '8月',
  sep: '9月',
  oct: '10月',
  nov: '11月',
  dec: '12月',
  to: '至',
  add: '新增',
  state: '状态',
  showImg: '图片展示',
  close: '关闭',
  followRemark: '跟进备注 Follow up remarks',
  plsSelect: '请选择',
  submit:'提交',
  remark:'注',
  cancelled:'已取消',
  importantHint:'重要提示',
  alreadyExists:'已经存在',
};
