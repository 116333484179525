/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'email': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M876.557 210.565H144.38c-44.805 0-81.256 36.45-81.256 81.257v514.471c0 44.806 36.45 81.257 81.256 81.257h732.178c44.806 0 81.257-36.45 81.257-81.257V291.822c0-44.807-36.45-81.257-81.257-81.257zm0 55.919c4.119 0 8.005.993 11.445 2.742l-377.534 272.26L132.93 269.233a25.16 25.16 0 0111.45-2.748h732.177zm0 565.147H144.38c-13.966 0-25.338-11.372-25.338-25.338V328.156L510.468 610.43l391.428-282.282v478.146c0 13.966-11.358 25.338-25.339 25.338z"/>'
  }
})
