export default {
  index: 'Application profile',
  studentManagement: 'Student Management',
  studentRegister: 'Student Registration',
  studentInfo: 'Personal Information of Students',
  studentInfoManagement: 'Student Information Management',
  studentApplicationSummary: 'Student Application Summary',
  commissionManagement: 'Commission Management',
  informationCenter: 'Information Center',
  newsInfo: 'News Information',
  institutionInfo: 'Colleges and Universities Information',
  videoCenter: 'Video Center',
  newsInfoEntrance: 'News information entrance',
  newsInfoDetail: 'News details',
  quickViewMPS: 'Quick View MPS'
};
