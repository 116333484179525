/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'student-management': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M674.226 538.603C707.006 499.9 726.8 449.85 726.8 395.26c0-20.134-2.694-39.65-7.743-58.206l105.841-57.247a54.866 54.866 0 0028.8-48.593 54.865 54.865 0 00-29.25-48.327L530.824 27.53a54.88 54.88 0 00-51.348-.024L185.097 182.864a54.87 54.87 0 00-29.31 48.35 54.848 54.848 0 0028.86 48.619l105.756 57.052c-5.073 18.605-7.779 38.181-7.779 58.376 0 56.92 21.518 108.887 56.846 148.233C280.658 572.45 230.062 615.632 191.7 669.93c-46.907 66.373-71.69 144.288-71.69 225.346 0 41.191 33.521 74.711 74.712 74.711h234.752c3.277 0 6.469-.412 9.503-1.177l49.965 28.848a38.806 38.806 0 0038.836 0l98.826-57.053a38.827 38.827 0 0019.418-33.63V792.859c0-13.872-7.403-26.7-19.418-33.63l-98.826-57.052a38.814 38.814 0 00-38.836 0l-98.826 57.052a38.827 38.827 0 00-19.418 33.63v99.457H197.682c.595-63.849 20.425-125.186 57.441-177.566 36.797-52.089 87.564-91.423 146.8-113.778a39.12 39.12 0 008.642-4.575c28.593 13.447 60.511 20.96 94.141 20.96 36.494 0 70.973-8.848 101.387-24.528a38.642 38.642 0 0011.42 6.456c123.996 44.589 207.591 162.153 208.793 293.14-20.061 1.517-35.863 18.278-35.863 38.727 0 21.445 17.391 38.836 38.836 38.836 41.19 0 74.711-33.52 74.711-74.71 0-153.816-91.095-293.274-229.764-356.674zM448.369 815.286l59.99-34.637 59.99 34.637v69.262l-59.99 34.637-59.99-34.637v-69.262zm56.337-275.603c-79.638 0-144.422-64.784-144.422-144.422s64.784-144.422 144.422-144.422 144.422 64.784 144.422 144.422-64.784 144.422-144.422 144.422zm0-366.516c-74.565 0-140.66 36.93-180.964 93.461l-64.76-34.94L505.12 101.793l245.48 129.895-64.832 35.074c-40.292-56.604-106.435-93.595-181.06-93.595z"/>'
  }
})
