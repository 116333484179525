import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Layout from '@/layout/index.vue';
import { deepClone } from '@/utils/function_utils';
import path from 'path';
import studentManagement from './student-management';
import commissionManagement from './commission-Management';
import informationCenter from './information-center';
import newsManagement from './news-management';
import mpsManagement from './mps-management';
Vue.use(VueRouter);

/**
 * ConstantRoutes
 * 没有权限需求的基本页面路由，所有角色都可以访问
 */
export const constantRoutes: RouteConfig[] = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/Login.vue'),
  },
  {
    path: '/reset',
    name: 'Reset',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/reset.vue'),
  },
  {
    path: '/404',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '@/views/error-page/error.vue'),
  },
  {
    path: '/redirect',
    component: Layout,
    meta: { hidden: true },
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import(/* webpackChunkName: "redirect" */ '@/views/redirect/index.vue'),
      },
    ],
  },
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: 'Index',
        component: () => import(/* webpackChunkName: "index" */ '../views/index/Index.vue'),
        meta: {
          icon: 'home',
          title: 'index',
          noDot: true,
        },
      },
    ],
  },

  ...studentManagement,
  // ...commissionManagement,
  // ...informationCenter,
  // {
  //   path: '/video-center',
  //   component: Layout,
  //   redirect: '/video-center/video-list',
  //   children: [
  //     {
  //       path: 'video-list',
  //       name: 'videoList',
  //       component: () => import(/* webpackChunkName: "videoCenter" */ '@/views/video-center/VideoList.vue'),
  //       meta: {
  //         icon: 'video-center',
  //         title: 'videoCenter',
  //         noDot: true,
  //       },
  //     },
  //   ],
  // },
];

// 异步路由
export const asyncRoutes: RouteConfig[] = [
  ...newsManagement,
  // ...mpsManagement,
  { path: '*', redirect: '/404' },
];
const createRouter = () => new VueRouter({
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      // 当且仅当 popstate 导航 (通过浏览器的 前进/后退 按钮触发) 时才可用
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes: constantRoutes,
});

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  (router as any).matcher = (newRouter as any).matcher;
}

// 二级以上的菜单降级成二级菜单
const formatRouter = (routes: any, basePath = '/', list: any[] = [], parent: any) => {
  routes.map((item: any) => {
    item.path = path.resolve(basePath, item.path)
    const meta = item.meta || {};
    if (!meta.parent && parent) {
      meta.parent = parent.path;
      item.meta = meta;
    }
    if (item.redirect && item.redirect !== 'noRedirect') {
      item.redirect = path.resolve(basePath, item.redirect)
    }
    if (item.children && item.children.length > 0) {
      const arr = formatRouter(item.children, item.path, list, item);
      delete item.children;
      list.concat(arr);
    }
    list.push(item);
  })
  return list
}

// 菜单降级
export const getFlatRoutes = (routes: RouteConfig[]) => {
  return routes.map((child: RouteConfig) => {
    if (child.children && child.children.length > 0) {
      child.children = formatRouter(child.children, child.path, [], child);
    }
    return child;
  })
}

// 降级后的路由
export const flatRoutes = getFlatRoutes(deepClone(asyncRoutes, ['component']));

export default router;
