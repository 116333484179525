export default {
  requiredField: 'Required fields!',
  emailValid: 'Please enter a valid email',
  birthdayValid: 'Please check the date of birth',
  checkRgstAddress: 'contact address exceeds 45 characters limit, please do not try to input the whole address, and input part of address in alternative contact address.',
  phoneRegionValid: 'The format of Phone number is incorrect, please re-enter!',
  contactPostcodeValid: 'postal code should be 5-6 digits',
  contactPostcodeTaiWanValid: 'Taiwan postal code should be 3-6 digits!',
  contactPostcodeUKValid: 'UK postal codes should be 5-8 digits!',
  englishNameValid: 'Please enter pinyin form as required!',
  stuPhoneNumberValid: "The student's phone number must be different of Follow up the contact's",
  stuEmailValid: "The student's email must be different of Follow up the contact's",
  issueDateValid: "Please check the date, the issue date {issueDate} cannot be after the expiration date {expriDate}",
  phoneRegionValid2: 'Please add the area code!',
  phoneRegionValid3: 'Please add the phone number! ',
}
