export default {
  tip: 'Tips',
  loading: 'Loading...',
  loginTimeout: 'Login timeout',
  errorMsg: 'Error message',
  reLogin: 'Re-Login',
  loginMsg: 'Login again',
  save: 'Save',
  cancel: 'Cancel',
  search: 'Search',
  reset: 'Reset',
  confirm: 'Confirm',
  opera: 'Opera',
  download: 'File Download',
  yes: 'Yes',
  no: 'No',
  underSubmission: 'Submitting ...',
  prevText: 'Page up',
  nextText: 'Page down',
  keyWord: 'Keyword',
  all: 'All',
  jan: 'Jan',
  feb: 'Feb',
  mar: 'Mar',
  apr: 'Apr',
  may: 'May',
  jun: 'Jun',
  jul: 'Jul',
  aug: 'Aug',
  sep: 'Sep',
  oct: 'Oct',
  nov: 'Nov',
  dec: 'Dec',
  to: 'to',
  add: 'Add',
  state: 'state',
  showImg: 'image display',
  close: 'closure',
  followRemark: 'Follow up remarks',
  plsSelect: 'please choose',
  submit:'submit',
  remark:'remark',
  cancelled:'cancelled',
  importantHint:'important hint',
  alreadyExists:'already exists',
};
