/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'edit': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M883.65 861.204H510.823c-20.193 0-36.244 15.688-36.244 35.426 0 19.737 16.051 35.425 36.244 35.425h372.829c20.193 0 36.25-15.688 36.25-35.425-.006-19.738-16.057-35.426-36.25-35.426zm-269.675-636.82c-8.822 0-19.528 4.915-28.534 13.916-13.952 13.957-18.084 32.016-9.318 40.776l161.771 161.771c3.108 3.108 7.389 4.598 12.242 4.598 8.822 0 19.528-4.915 28.534-13.916 13.957-13.957 18.084-32.015 9.318-40.776L626.217 228.982c-3.113-3.108-7.388-4.598-12.242-4.598z"/><path pid="1" d="M753.27 143.084L882.4 272.215 365.978 788.639l-206.935 75.484 77.47-204.288L753.27 143.084m0-75.04a60.534 60.534 0 00-42.942 17.788l-525.445 525.45a60.723 60.723 0 00-13.84 21.407L69.284 901.023c-10.49 27.663 10.896 54.927 37.638 54.927 4.582 0 9.329-.804 14.09-2.54l271.35-98.984a60.723 60.723 0 0022.129-14.111l525.163-525.159c23.716-23.715 23.716-62.161 0-85.877L796.211 85.832a60.544 60.544 0 00-42.941-17.787z"/>'
  }
})
