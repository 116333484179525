import login from './login';
import route from './route';
import common from './common';
import layout from './layout';
import stuManagement from './student_management';
import validate from './validate';
import message from './message';
import newsManagement from './news_management';

export default {
  index: {
    enrolledSuccessProportion: '{text}/Total number of students',
    studentTotalSum: 'Student Numbers (Application Counts)',
    applyStatus: 'Application status',
    applyCount: 'Application count',
    studentCount: 'Student count',
    year: '{year} year',
    nowStudentCount: 'Number of students in {year}',
    nowReadingCount: 'Number of {text} in {year}',
    allStudentCount: 'Total students',
    allReadingCount: 'Total {text}',
    applicationCountBarTitle: 'Number of Students application/{text}',
    countryDistributionPieTitle: 'Distribution of students\' application intention by country/region',
    enrolledSuccess: 'Successful entry reading',
    osCount: 'OS',
  },
  login,
  route,
  common,
  message,
  ...layout,
  stuManagement,
  validate,
  newsManagement,
};
