/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'information': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M426.667 614.4H290.133C271.36 614.4 256 629.76 256 648.533s15.36 34.134 34.133 34.134h136.534c18.773 0 34.133-15.36 34.133-34.134S445.44 614.4 426.667 614.4zm170.666-170.667h-307.2c-18.773 0-34.133 15.36-34.133 34.134S271.36 512 290.133 512h307.2c18.774 0 34.134-15.36 34.134-34.133s-15.36-34.134-34.134-34.134zm0-170.666h-307.2c-18.773 0-34.133 15.36-34.133 34.133s15.36 34.133 34.133 34.133h307.2c18.774 0 34.134-15.36 34.134-34.133s-15.36-34.133-34.134-34.133z"/><path pid="1" d="M802.133 238.933V204.8c0-75.093-61.44-136.533-136.533-136.533H221.867c-75.094 0-136.534 61.44-136.534 136.533v614.4c0 75.093 61.44 136.533 136.534 136.533h580.266c75.094 0 136.534-61.44 136.534-136.533V375.467c0-75.094-61.44-136.534-136.534-136.534zM870.4 819.2c0 37.547-30.72 68.267-68.267 68.267H221.867c-37.547 0-68.267-30.72-68.267-68.267V204.8c0-37.547 30.72-68.267 68.267-68.267H665.6c37.547 0 68.267 30.72 68.267 68.267v512c0 18.773 15.36 34.133 34.133 34.133s34.133-15.36 34.133-34.133V307.2c37.547 0 68.267 30.72 68.267 68.267V819.2z"/>'
  }
})
