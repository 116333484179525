import Cookies from 'js-cookie';
import {moreUser} from './more_user';


const TOKEN_KEY = 'issue-access-token';
// export const getToken = () => Cookies.get(TOKEN_KEY) || '';
// export const removeToken = () => Cookies.remove(TOKEN_KEY);
// export const setToken = (token: string) => Cookies.set(TOKEN_KEY, token);

export const getToken = () => sessionStorage.getItem(moreUser(TOKEN_KEY)) || '';
export const removeToken = () => sessionStorage.removeItem(moreUser(TOKEN_KEY));
export const setToken = (token: any) => sessionStorage.setItem(moreUser(TOKEN_KEY), token);
