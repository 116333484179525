import { UserModule } from '@/store/modules/user';
import {getStaffId} from './storage_session'
const UserModules:any=()=>{ return UserModule};
export const getStaffiid = (): string => {
    if ( UserModules && UserModules.staffId ) {
        return UserModules.staffId
    } else if (getStaffId()) {
        return getStaffId();
    } else {
        return ''
    }
}
export const moreUser = (sessionName: string ) => {
    return `${sessionName}_${getStaffiid()}`
}
// 获取唯一id 下的所有用户信息
// export const getMoreUser=(staffId:any,sessionName:string)=>{
//     const userId:any=staffId;
//     const data:any=sessionStorage.getItem(userId) || '';
//     let returnData:any='';
//     if(data){
//         const json:any=JSON.parse(data);
//         let flag:boolean=false;
//         Object.keys(json).forEach((key:any)=>{
//             if(key===sessionName){
//                 flag=true;
//                 returnData=json[key];
//             }
//         })
//     }else{
//         sessionStorage.setItem(IssueKey+userId,'{}');
//     }
//     return returnData;
// }
// // 设置唯一用户id
// export const setMoreUser=(sessionName:string,key:any)=>{
//     const userId:any=UserModule.staffId;
//     const data:any=sessionStorage.getItem(userId) || '';
//     let setJson:any={};
//     if(data){
//         const json:any=JSON.parse(data);
//         Object.keys(json).forEach((key:any)=>{
//             if(key===sessionName){
//                 json[key]=key;
//             }
//         })
//         setJson=JSON.stringify(json);
//         sessionStorage.setItem(IssueKey+userId,setJson);
//     }else{
//         setJson=JSON.stringify({[sessionName]:key});
//         sessionStorage.setItem(IssueKey+userId,setJson);
//     }
// }
// // 删除用户
// export const removeMoreUser=()=>{
//     const userId:any=UserModule.staffId;
//     sessionStorage.removeItem(IssueKey+userId);
// }
