/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'download': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M768 352a224 224 0 010 448h-32a32 32 0 010-64h32a160 160 0 000-320 147.2 147.2 0 00-27.84 2.56 32 32 0 01-38.08-27.52 192 192 0 00-380.16 0 32 32 0 01-38.08 27.52A147.2 147.2 0 00256 416a160 160 0 000 320h32a32 32 0 010 64h-32a224 224 0 010-448h8.32a256 256 0 01495.36 0z"/><path pid="1" d="M480 608a32 32 0 0164 0v288a32 32 0 01-64 0z"/><path pid="2" d="M600 746.88a32 32 0 0148 42.24l-112 128a32 32 0 01-48 0l-112-128a32 32 0 1148-42.24l88 100.48z"/>'
  }
})
