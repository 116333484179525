export default {
  requiredField: '必填项',
  emailValid: '请输入正确的邮箱地址',
  birthdayValid: '请检查出生日期',
  checkRgstAddress: '超过45字数限制, 建议避免填上整個地址, 以及请把部分內容填在备选联系地址',
  phoneRegionValid: '电话号码格式不正确，请重新输入！',
  contactPostcodeValid: '国内邮政编码应该为5-6位！',
  contactPostcodeTaiWanValid: '台湾邮政编码应该为3-6位！',
  contactPostcodeUKValid: '英国邮政编码应该为5-8位！',
  englishNameValid: '请根据要求输入拼音形式！',
  stuPhoneNumberValid: '学生手机号不能与跟进人手机号一致',
  stuEmailValid: '学生邮箱不能与跟进人邮箱一致',
  issueDateValid: "请检查日期, 签发日期{issueDate}不能在有效期{expriDate}后",
  phoneRegionValid2: '请补充区号！',
  phoneRegionValid3: '请补充手机号！',
}
