import axios, { ResponseData, checkCookieValidity, loginTimeoutMessageBox } from './request_config';
import { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { Message } from 'element-ui';
import i18n from '@/lang/index';
import { UserModule } from '@/store/modules/user';

const loginService: AxiosInstance = axios.create();

loginService.interceptors.request.use((config: AxiosRequestConfig) => {
  config.headers.Authorization = 'Basic aXNzdWVDZW50ZXI6aXNzdWVDZW50ZXI=';
  if (UserModule.token) {
    config.headers.at = UserModule.token;
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

loginService.interceptors.response.use((response: AxiosResponse<ResponseData|any> | any) => {
  const res: ResponseData = response.data;
  const code = res.code;
  if (!code) {
    return response;
  }
  if (code === '1003') {
    return response
  } else {
    const message = res.message ? res.message : i18n.t('message.unknownError') as string;
    Message.error({
      message: `错误码：${code}，${message}`,
      dangerouslyUseHTMLString: true,
    });
    return Promise.reject()
  }

}, (error: AxiosError) => {
  const data = error.response?.data;
  if (data && data.code) {
    if (data.code === '0006') { // token失效
      checkCookieValidity();
    }
  } else {
    let message = data.error_description;
    if (message === '10086') { // 用户没有邀请码
      message = i18n.t('login.noInvitationCode') as string;
    } else if (message === '10087') { // 用户邀请码无效
      message = i18n.t('login.invalidInvitationCode') as string;
    } else if (message && message.includes('Invalid refresh token (expired)')) { // 登录超时
      loginTimeoutMessageBox(i18n.t('common.loginTimeout') as string);
      return Promise.reject(data);
    }
    Message.error({
      message,
      dangerouslyUseHTMLString: true,
    });
    return Promise.reject(data);
  }
})

export default loginService
