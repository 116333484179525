export default {
  tip: '提示',
  loading: '加載數據中...',
  loginTimeout: '登錄超時',
  errorMsg: '錯誤信息',
  reLogin: '重新登錄',
  save: '保存',
  cancel: '取消',
  search: '搜索',
  reset: '重置',
  confirm: '確定',
  opera: '操作',
  download: '文件下載',
  yes: '是',
  no: '否',
  underSubmission: '提交中 ...',
  prevText: '上一頁',
  nextText: '下一頁',
  keyWord: '關鍵字',
  all: '全部',
  jan: '1月',
  feb: '2月',
  mar: '3月',
  apr: '4月',
  may: '5月',
  jun: '6月',
  jul: '7月',
  aug: '8月',
  sep: '9月',
  oct: '10月',
  nov: '11月',
  dec: '12月',
  to: '至',
  add: '新增',
  state: '狀態',
  showImg: '圖片展示',
  close: '關閉',
  followRemark: '跟進備註 Follow up remarks',
  plsSelect: '請選擇',
  submit:'提交',
  remark:'註',
  cancelled:'已取消',
  importantHint:'重要提示',
  alreadyExists:'已經存在',
};
