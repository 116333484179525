/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'apply-follow': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M109.625 142.65C142.05 107.3 188.375 87.575 243 87.575h538.025c43 0 77.625 11.45 104.7 30.425 26.7 18.75 43.875 43.25 54.775 66.3a228.5 228.5 0 0118.8 61.85 228.599 228.599 0 012.65 28.3v.95h-37.5 37.5v624.625c0 12.15-6.05 23.5-16.225 30.4a38 38 0 01-34.6 4.1l-321.4-120.75L268.25 934.5a38 38 0 01-34.625-4.1A36.75 36.75 0 01217.4 900V463.25H99.5c-20.7 0-37.5-16.55-37.5-36.95V275.4c0-50.75 15.675-97.875 47.625-132.75zm107.8 246.75V275.425h75v571l283.9-106.675a37.914 37.914 0 0126.75 0l283.9 106.65V274.9l-.2-3.5a160.05 160.05 0 00-1.6-13.875 156.2 156.2 0 00-12.6-41.925c-7-14.65-16.75-27.875-30.375-37.425-13.275-9.325-32.5-16.75-61.2-16.75H242.975c-35.4 0-60.8 12.3-77.625 30.65C148.05 211 137 239.325 137 275.425v114h80.425zm37.5-150.925c9.95 0 19.5 3.875 26.5 10.8 7.05 6.95 11 16.325 11 26.125h-75c0-20.4 16.8-36.925 37.5-36.925zm129.9 187.85c0-20.4 16.775-36.925 37.5-36.925H745.1c20.725 0 37.5 16.525 37.5 36.925s-16.775 36.925-37.5 36.925H422.35c-20.7 0-37.5-16.5-37.5-36.925h-.025zm0 150.9c0-20.4 16.775-36.925 37.5-36.925H565.8c20.7 0 37.5 16.525 37.5 36.925s-16.8 36.925-37.5 36.925H422.3c-20.7 0-37.5-16.525-37.5-36.925z"/>'
  }
})
